import { useState } from 'react';
import Navbar02 from '../../../components/navbar02/Navbar02';
import Footer from '../../../components/footer/Footer';

import MonteCarlo2Demo from './MonteCarlo2Demo';



const MonteCarlo1Expose = () => {
    const [ whichPage, setWhichPage ] = useState('demo'); // __init__ demo page

    const Landing = () => {
        return (
            <section id='standardized-seattle-background' className='standardized-maxwidth standardized-padding-LR landing-div-flexbox'>

                    <div id='project-font-andale-mono-h2'>PROJECT</div>
                    <div id='project-font-andale-mono-h1'>MONTE CARLO SIMULATOR</div>
                    <div id='div-buttons-architecture-or-demo'>
                        <button 
                            className={ `btn-${whichPage === 'demo' ? 'activate' : ''}` }
                            onClick={ () => {setWhichPage('demo')} }
                            name='demo'
                        >VIEW DEMO</button>

                        <button
                            className={ `btn-${whichPage === 'architecture' ? 'activate' : ''}` }
                            onClick={ () => {setWhichPage('architecture')} }
                            name='architecture'
                        >VIEW ARCHITECTURE</button>
                    </div>

            </section>
        );
    }

    if (whichPage === 'demo') {
        return (
            <div className='body'>
                    <Navbar02 />
                    <div style={{ height: '57px' }}></div>
                    <Landing />
                    <MonteCarlo2Demo />
                    <Footer />
            </div>
        );
    } else if (whichPage === 'architecture') {
        return(
            <div className='body'>
                <Navbar02 />
                <div style={{ height: '57px' }}></div>
                <Landing />
                {/* <RentCalculator3Demo /> */}
                <Footer />
            </div>
        );
    } else {
        return( 'there is an error in the projects page');
    };
}



export default MonteCarlo1Expose;