// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-center {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;

    background-color: #1a1e2a;
    width: 100%;
    height: 50px;

    padding: 0;
    border: 0px;
    margin: 0px;
}

.font-gray {
    font-family: Andale Mono;
    font-size: 13px;
    color: #818FA3;
    font-weight: lighter;

    letter-spacing: 1px;
}

.font-green {
    font-family: Andale Mono;
    font-size: 13px;
    color: #01C9C9;
    font-weight: lighter;

    letter-spacing: 1px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/FooterStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;;IAEnB,yBAAyB;IACzB,WAAW;IACX,YAAY;;IAEZ,UAAU;IACV,WAAW;IACX,WAAW;AACf;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,cAAc;IACd,oBAAoB;;IAEpB,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,cAAc;IACd,oBAAoB;;IAEpB,mBAAmB;AACvB","sourcesContent":[".div-center {\n    display: flex;\n    flex-direction: column;\n    justify-content:center;\n    align-items: center;\n\n    background-color: #1a1e2a;\n    width: 100%;\n    height: 50px;\n\n    padding: 0;\n    border: 0px;\n    margin: 0px;\n}\n\n.font-gray {\n    font-family: Andale Mono;\n    font-size: 13px;\n    color: #818FA3;\n    font-weight: lighter;\n\n    letter-spacing: 1px;\n}\n\n.font-green {\n    font-family: Andale Mono;\n    font-size: 13px;\n    color: #01C9C9;\n    font-weight: lighter;\n\n    letter-spacing: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
