import './styles/BigwordsStatementOfOdds.css';

const BigwordsStatementOfOdds = (props) => {

    // ##### utility functions ############################
    const formatUsd = (num) => {
        if (num==='') {
            return ''
        } else {
            num = parseFloat(num)
            return '$' + num.toFixed(2).toLocaleString()
        }
        }
    
    /////////////////////////////////////////////////////////////////////////
    ///// qc: good way to traverse thru POST return value
    // console.log('On BigwordsStatementOfOdds Page - props.userInputConfidenceLevel: ', props.userInputConfidenceLevel);

    // console.log('On BigwordsStatementOfOdds Page - props.formData: ', props.formData);
    /////////////////////////////////////////////////////////////////////////

    const userInputConfidenceLevel = props.userInputConfidenceLevel    
    if (userInputConfidenceLevel !== '') {
        const apiData_upper = props.formData.post_response.monteCarlo_confidenceLevels[ userInputConfidenceLevel ].upper_bound
        console.log('apiData_upper: ', apiData_upper)
    
        const apiData_lower = props.formData.post_response.monteCarlo_confidenceLevels[ userInputConfidenceLevel ].lower_bound
        console.log('apiData_lower: ', apiData_lower)

        return (
            <div className='standardized-font-monteCarlo-h1 align-center'>
                <div>
                    There is <span id='highlight'>{userInputConfidenceLevel}%</span> chance tomorrows stock price will fall between: <span id='highlight'>{ formatUsd( apiData_upper ) }</span> and <span id='highlight'>{ formatUsd( apiData_lower ) }</span>
                </div>
            </div>
        );

    } else {
        console.log('False block executed.');
    }
}

export default BigwordsStatementOfOdds;