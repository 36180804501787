// ©

import './FooterStyles.css';

const Footer = () => {
    return (
        <div className='div-center'>
            <div className='font-green'> <span className='font-gray'>REUBEN QUINTO</span> ©2024</div>
        </div>
    );
}

export default Footer;