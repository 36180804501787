import React, { useEffect } from 'react';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';

import './styles/RentCalculator2DemoStyles.css';



const RentCalculator = () => {

  // ##### utility functions ############################
  const round = (num, numDigits = 2) => { // Set default to 2 decimal places
    num = parseFloat(num)
    
    if ( isNaN(num) || num===0 || !isFinite(num) ) {
      return ''; // Handle non-numeric input gracefully
    }
  
    // Ensure numDigits is a non-negative integer
    numDigits = Math.max(0, Math.floor(numDigits));
  
    return num.toFixed(numDigits);
  };

  const strip = (str) => {
    str = str.replace('$', '').replace(',','').replace('%','')
    return str
  }

  const formatUsd = (num) => {
    if (num==='') {
      return ''
    } else {
      num = parseFloat(num)
      return '$' + num.toLocaleString()
    }
  }

  const formatPct = (num) => {
    if ( num==='' ) {
      return ''
    } else {
      return num/100 + '%'
    }
  }

  // ##### user inputs ##################################
  const [rentAmount, setRentAmount] = useState('');
  const [utilityAmount, setUtilityAmount] = useState('');
  const [budgetedRent, setBudgetedAmount] = useState('');

  
  const handleChangeRent = (event) => {

    var input_val = strip(event.target.value)
    input_val = parseFloat(input_val)

    if ( isNaN(input_val) ) {
      setRentAmount('');
    } else {
      setRentAmount( input_val );
    };
  }
  
  const handleChangeUtilities = (event) => {

    var input_val = strip(event.target.value)
    input_val = parseFloat(input_val)

    if ( isNaN(input_val) ) {
      setUtilityAmount('');
    } else {
      setUtilityAmount(input_val);
    }
  }
  
  const handleBudgetedAmount = (event) => {
    var input_val = strip(event.target.value)
    input_val = parseFloat(input_val)

    if ( isNaN(input_val) ) {
      setBudgetedAmount('');
    } else {
      setBudgetedAmount(input_val);
    }
  }
  
  // ##### tax info #####################################
  const [federalEffectiveRate, setFederalEffectiveRate] = useState('');
  const [ficaEffectiveRate, setFicaEffectiveRate] = useState('');
  const [stateEffectiveRate, setStateEffectiveRate] = useState('');

  const handleFederalEffectiveRate = (x) => {
    // console.log('this is a test: ' + Object.keys(x))
    setFederalEffectiveRate( parseFloat(x) * 0.01 )
    // console.log('federalEffectiveRate: ' + federalEffectiveRate)
  }

  const handleFicaEffectiveRate = (x) => {
    setFicaEffectiveRate( parseFloat(x) * 0.01 )
    // console.log('ficaEffectiveRate: ' + ficaEffectiveRate)
  }

  const handlestateEffectiveRate = (x) => {
    setStateEffectiveRate( parseFloat(x) * 0.01 )
    // console.log('stateEffectiveRate: ' + stateEffectiveRate)
  }

  // ##### proprietary calculations #####################
  // monthly income
  const [monthlyIncome, setMonthlyIncome] = useState('');

  useEffect( () => {
    var numerator = ( (rentAmount + utilityAmount) * 100 )
    // console.log('numerator: ' + numerator)

    var denominator = budgetedRent
    // console.log('denominator: ' + denominator)

    setMonthlyIncome( (numerator/denominator) )
    // console.log('monthlyIncome: ' + monthlyIncome)
  }, [rentAmount, utilityAmount, budgetedRent])

  // net income
  const [netIncome, setNetIncome] = useState('');

  useEffect( () => {
    setNetIncome( monthlyIncome * 12 )
    // console.log('netIncome: ' + netIncome)
  }, [monthlyIncome])

  // federal amount
  const [federalAmount, setFederalAmount] = useState('');

  useEffect( () => {
    setFederalAmount( (federalEffectiveRate * netIncome).toFixed(2) )
    // console.log('federalAmount: ' + federalAmount)
  }, [federalEffectiveRate, netIncome])

  // fica amount
  const [ficaAmount, setFicaAmount] = useState('');

  useEffect( () => {
    setFicaAmount( (ficaEffectiveRate * netIncome).toFixed(2) )
    // console.log('ficaAmount: ' + ficaAmount)
  }, [ficaEffectiveRate, netIncome])

  // state amount
  const [stateAmount, setStateAmount] = useState('');

  useEffect( () => {
    setStateAmount( (stateEffectiveRate * netIncome).toFixed(2) )
    // console.log('stateAmount: ' + stateAmount)
  }, [stateEffectiveRate, netIncome])

  // gross income
  const [grossIncome, setGrossIncome] = useState('');

  useEffect(() => {

    var numerator = netIncome
    // console.log('numerator02: ' + numerator)

    var denominator = 1 - ( federalEffectiveRate + ficaEffectiveRate + stateEffectiveRate )
    // console.log('denominator02: ' + denominator)

    setGrossIncome( numerator/denominator )
    // console.log('grossIncome03: ' + grossIncome)
  }, [netIncome, federalEffectiveRate, ficaEffectiveRate, stateEffectiveRate])

  // ##### fetch module #################################################
  // // for testing
  // fetch("http://127.0.0.1:5000/dev")
  //   .then((response) => response.text())
  //   .then((result) => console.log(result))
  //   .catch((error) => console.error(error));

  // fire ONLY when all input fields have been filled out
  if ( rentAmount !== '' && utilityAmount !== '' && budgetedRent !== '' ) {
    fetch(`http://${process.env.REACT_APP_DOMAINNAME_URL}:5000/dev`)
      .then((response) => response.json())
      .then((result) => {
        handleFederalEffectiveRate( result['page_data']['2023']['federalEffectiveRate'] ) 
        handleFicaEffectiveRate( result['page_data']['2023']['ficaEffectiveRate'] )
        handlestateEffectiveRate( result['page_data']['2023']['stateEffectiveRate'] )
      })
      .catch((error) => console.error(error));
  }

  return (
    <div id="rent-calculator">
      
      <section className="section-standard-rentcalculator">
        <UserInputs
          rentAmount={rentAmount}
          utilityAmount={utilityAmount}
          budgetedRent={budgetedRent}
          handleChangeRent={handleChangeRent}
          handleChangeUtilities={handleChangeUtilities}
          handleBudgetedAmount={handleBudgetedAmount}
        />
      </section>

      <section className="section-standard-rentcalculator standardized-font-monteCarlo-h1">
        <Msg
          grossIncome={ formatUsd(round(grossIncome, 2)) } 
        />
      </section>

      <section className="section-standard-rentcalculator">
        <MonthlyRentInput
          rentAmount={ formatUsd(round(rentAmount, 2)) }
          utilityAmount={ formatUsd(round(utilityAmount, 2)) }
          budgetedRent={ formatPct(round(budgetedRent, 2)) }
        />
      </section>

      <section className="section-standard-rentcalculator">
        <AnnualTax
          federalEffectiveRate={ round(federalEffectiveRate, 2) + '%' }
          ficaEffectiveRate={ round(ficaEffectiveRate, 2) + '%' }
          stateEffectiveRate={ round(stateEffectiveRate, 2) + '%' }

          federalAmount={ formatUsd(round(federalAmount, 2)) }
          ficaAmount={ formatUsd(round(ficaAmount, 2)) }
          stateAmount={ formatUsd(round(stateAmount, 2)) }
        />
      </section>

      <section className="section-standard-rentcalculator">
        <AnnualRequiredIncome
          grossIncome={ formatUsd(round(grossIncome, 2)) }
        />
      </section>

    </div>
  );
};

const UserInputs = (props) => {
  return (
      <section>

        <div>
          <label>Rent Amount</label> <br />
          {/* test: {props.rentAmount} <br /> */}
          <NumericFormat
            className="input-dollar"
            prefix="$"
            thousandSeparator=","
            value={props.rentAmount}
            onChange={props.handleChangeRent} />
          <br />
        </div>
        
        <br />

        <div>
          <label>Utilities Amount</label> <br />
          {/* test: {props.utilityAmount} <br /> */}
          <NumericFormat
            className="input-dollar"
            prefix="$"
            thousandSeparator=","
            value={props.utilityAmount}
            onChange={props.handleChangeUtilities} />
          <br />
        </div>
        
        <br />

        <div>
          <label>Budgeted Rent</label> <br />
          {/* test: {props.budgetedRent} <br /> */}
          <NumericFormat
            className="input-dollar"
            suffix={'%'}
            decimalScale={2}
            value={props.budgetedRent}
            onChange={props.handleBudgetedAmount} />
          <br />
        </div>

        <br />

      </ section>
  );
}

const Msg = (props) => {
  return (
    <section className="msg standard-panel">
      <label id="msg-estimated-salary" >Your Estimated Salary (before Taxes):</label> <br />
      {/* test: {props.grossIncome} <br /> */}
      <label id="msg-estimated-salary-green">{props.grossIncome}</label>
    </section>
  )
}

const MonthlyRentInput = (props) => {
  return (
    <section>
      <label className="rentcalc-standard-h1" >Monthly Rent Input</label>
      <div style={{ height: '15px' }}></div>

      <table>

        <thead>
          <tr>
            <td className="table-headers" >Financial Account</td>
            <td className="table-headers" >Percentages</td>
            <td className="table-headers" >Amount</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>rent cost</td>
            <td></td>
            <td>{props.rentAmount}</td>
          </tr>
          <tr>
            <td>utilities</td>
            <td></td>
            <td>{props.utilityAmount}</td>
          </tr>
          <tr>
            <td>budgeted rent</td>
            <td>{props.budgetedRent}</td>
            <td></td>
          </tr>
        </tbody>

      </table>

    </section>
  );
}

const AnnualTax = (props) => {
  return (
    <div>
      <label className="rentcalc-standard-h1" >Annual Tax</label>
      <div style={{ height: '15px' }}></div>

      <table>

          <thead>
            <tr>
              <td className="table-headers" >Financial Account</td>
              <td className="table-headers" >Percentage</td>
              <td className="table-headers" >Amount</td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>federal</td> 
              <td>{props.federalEffectiveRate}</td>
              <td>{props.federalAmount}</td>
            </tr>
            <tr>
              <td>fica</td>
              <td>{props.ficaEffectiveRate}</td>
              <td>{props.ficaAmount}</td>
            </tr>
            <tr>
              <td>state</td>
              <td>{props.stateEffectiveRate}</td>
              <td>{props.stateAmount}</td>
            </tr>
          </tbody>
          
      </table>

    </div>
  );
}

const AnnualRequiredIncome = (props) => {
  return(
    <div>
      <label className="rentcalc-standard-h1" >Annual Required Income</label>
      <div style={{ height: '15px' }}></div>

      <table>

        <thead>
          <tr>
            <td className="table-headers">Financial Account</td>
            <td className="table-headers">Percentage</td>
            <td className="table-headers">Amount</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>gross income</td>
            <td></td>
            <td>{props.grossIncome}</td>
          </tr>
        </tbody>

      </table>

    </div>
  );
}

export default RentCalculator;