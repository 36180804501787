//////////////////////////////////////////////////////////////////////////
///// lessons learned (2):
/////   1. monte carlo conf level is calculated on the index positions.
/////       ie. point estimator of index position
/////   2. inorder for you to calculate conf levels...
/////       you need to pass in all 100,000 obs, thru http...
/////       - solutions:
/////           restrict conf levels: {80, 90, 95, 99}
/////           memoize these levels on the react side & send with form data
/////   3. dealing with even situations
/////       idx_median_ceil = math.ceil( median )
/////       idx_median_floor = math.floor( median )
/////       pe = ( arr[idx_median_ceil] - arr[idx_median_ceil] ) / 2  => (rv is returns)
/////   1. tiwa bullshit
/////   2. adding relative file paths with OS
/////       file_name = '{0}.csv'.format( stock_ticker[stock] )
/////       file = os.path.join('.', 'data', 'data', file_name )
/////   3. proper curl --data_raw
/////       curl --location --request GET "http://localhost:5000/api/v1/monteCarloStocks" --header "Content-Type: application/json"  --data-raw "{\"stock\": \"gspc\", \"num_sims\": 30}"
/////   4. no special characters passing through curl
/////       '&'
/////   5. making DataFrame serializable
//////////////////////////////////////////////////////////////////////////

import { useState } from 'react';

import BigwordsStatementOfOdds from './components/BigwordsStatementOfOdds';
import GraphTimeSeries from './components/GraphTimeSeries';
import GraphHistogram from './components/GraphHistogram';
import TablePercentiles from './components/TablePercentiles';
import TableSummaryStats from './components/TableSummaryStats';

import './styles/MonteCarloStyles.css';
import '../../../index.css';



const MonteCarlo = () => {

    ///// user input: select stock, enter number of siulations ///
    const [formData, setFormData] = useState({
        'stock': '',
        'num_sims': '',
        'post_response': ''
    })
    
    const handleChange = (event) => {
        // console.log('event.target.name: ', event.target.name)
        // console.log('event.target.value: ', event.target.value)

        if (event.target.name === 'num_sims' ) {
            if ( event.target.value < 0 ||  event.target.value > 1000000 ) {
                alert('Number of Simulations must be greater thnan 0 or less than 1000000')
            } else {
                setFormData({
                    ...formData,
                    [event.target.name]: event.target.value,
                });
            }
        } else {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
            });
        }

    };

    ///// user input: select confidence level ////////////////////
    const [userInputConfidenceLevel, setUserInputConfidenceLevel] = useState('');
    
    const handleSetUserInputConfidenceLevel = (event) => {
        setUserInputConfidenceLevel( event.target.value );
    }

    //////////////////////////////////////////////////////////////
    /// version:
    ///     v0.01
    /// procedures: (GET MONTE CARLO DATA)
    ///     1. pass in parameters (via form)
    ///     2. POST to flask endpoint
    //////////////////////////////////////////////////////////////

    const onSubmit = async (event) => {
        event.preventDefault();

        const request = await fetch(`http://${process.env.REACT_APP_DOMAINNAME_URL}:5000/api/v1/monteCarloStocks`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(formData),
        })
        .then((response) => response.json())
        .then((result) => {
            // print statuses
            // console.log('Request success.')
            
            // REQUIRED: convert str to json (yes, results.body is a str)
            // console.log('Flask Response: ', result)
            // console.log('Flask Response: ', result.body)
            const payload = JSON.parse( result.body )

            // saving the result data
            setFormData({ ['post_response']: payload })


            // if success then clear the form
            event.target.reset()
        })
        .catch(error => {
            // print statuses
            console.log('Request failed. Error msg: ', error)
        })
    }

    return (
        <>
            <section className='standardized-maxwidth standardized-margin-TopBottom standardized-margin-LR standardized-padding-LR standardized-padding-TopBottom standardized-background-color-02 standardized-boarders'>

                <label>Select Stock</label> <br />
                <form onSubmit={onSubmit} >

                    <select name='stock' defaultValue='disable' onChange={handleChange} required className='input-stock'>
                        <option value='disable' disabled    >Select Stock</option>
                        <option value='gspc'                >S&P 500 (^GSPC)</option>
                        <option value='goog'                >Alphabet Inc. (GOOG)</option>
                        <option value='jpm'                 >JPMorgan Chase & Co. (JPM)</option>
                        <option value='tm'                  >Toyota Motor Corporation (TM)</option>
                        <option value='tsla'                >Tesla, Inc. (TSLA)</option>
                    </select><br />
                    <br />

                    <label>Number Of Simulations</label> <br />
                    <input name='num_sims' placeholder='Number Of Simulations' onChange={handleChange} className='input-stock'></input><br/>
                    <br />

                    <div className='div-btn-center'>
                        <button className='btn-simulate'>Run Simulation</button>
                    </div>

                </form>
            </section>

            <section className='standardized-maxwidth standardized-margin-TopBottom standardized-margin-LR standardized-padding-LR standardized-padding-TopBottom standardized-background-color-02 standardized-boarders'>
                <label>Confidence Level</label> <br />
                <form className='btn-radio'>
                    <input type="radio" name="confidence_level" value="99" onChange={handleSetUserInputConfidenceLevel}/><label>99%</label>
                    <input type="radio" name="confidence_level" value="95" onChange={handleSetUserInputConfidenceLevel}/><label>95%</label>
                    <input type="radio" name="confidence_level" value="90" onChange={handleSetUserInputConfidenceLevel}/><label>90%</label>
                    <input type="radio" name="confidence_level" value="80" onChange={handleSetUserInputConfidenceLevel}/><label>80%</label>
                </form>
            </section>

            <section className='standardized-maxwidth standardized-margin-TopBottom standardized-margin-LR standardized-padding-LR standardized-padding-TopBottom standardized-background-color-02 standardized-boarders'>
                <BigwordsStatementOfOdds formData={formData} userInputConfidenceLevel={userInputConfidenceLevel} />
            </section>

            <section className='standardized-maxwidth standardized-margin-TopBottom standardized-margin-LR standardized-padding-LR standardized-padding-TopBottom standardized-background-color-02 standardized-boarders'>
                <GraphTimeSeries formData={formData} userInputConfidenceLevel={userInputConfidenceLevel} />

                <GraphHistogram formData={formData} userInputConfidenceLevel={userInputConfidenceLevel} />

                <TablePercentiles formData={formData} /><br />

                <TableSummaryStats formData={formData} />
            </section>

        </>
    );
}



export default MonteCarlo;