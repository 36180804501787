import ux_design from './images/ux_design.JPG';
import sequence_diagram from './images/sequence_diagram.JPG';

import './styles/RentCalculator3Architecture.css';

const RentCalculatorArchitecture = () => {
    return(
        <>
            <div className='standardized-background-color-02 standardized-maxwidth standardized-padding-LR standardized-padding-TopBottom'>
                
                <section>
                    <h1 className='font-expose-h1'>MOTIVATION</h1>

                    <p>
                        When was the last time you went to apartments.com? Have you ever wondered 'How much money do I need to make inorder to afford this apartment?'<br/>
                        <br/>
                    
                        These are  2 things people dont do the math on when shopping for an apartment, granted these are not easy things to calculate (2):
                    </p>
                    <ol>
                        <li>Setting aside 30% of your income to rent</li>
                        <li>Income tax</li>
                    </ol>
                    <br/>

                    <p>
                        On the first point, the general rule of thumb is to set aside 30% if your income to rent. Sadly, most people live paycheck to paycheck. Not you though, you'll come to the negotiating table prepared with how much your life costs.<br/>
                        <br/>

                        On the second point, most people dont even consider tax as an expense. As Tony Robbins would say 'Ignorance is NOT bliss, and what you dont know will HURT you.' Make no mistake about it, income-tax is one of the biggest expense any american will take on. The question is, how much is income-tax costing you?<br/>
                        <br/>

                        The goal of this project is to help one come up with a number to bring to the negotiating table at a job interview. This project makes it easy to add in: your rent, your budget for rent and adds in income-taxes.<br/>
                        <br/>

                        Never sell yourself short and take care of your family financially.<br/>
                        <br/>
                    </p>
                </section>

                <hr/><br/> {/* ========================================================================== */}

                <section>
                    <h1 className='font-expose-h1'>FRONT-END</h1>

                    <h2>User Story</h2>

                    <p>
                        My name is Aaron and I just found my dream apartment on apartments.com. I'd like to know how much money I need inorder to afford this apartment?<br/>
                        <br />
                    </p>

                    <h2>UI Proposal</h2>
                    <img src={ux_design} style={{ 'width':'100%' }} />
                </section>

                <hr/><br/> {/* ========================================================================== */}

                <section>
                    <h1 className='font-expose-h1'>BACKEND-END</h1>

                    <h2>Sequence Diagram</h2>
                    <img src={sequence_diagram} style={{ 'width':'100%' }} /><br/>
                    <br/>

                    <h2>Data Endpoint</h2>
                    <p>
                        <b>Get Income Tax Info by Income</b><br/>
                        <br/>

                        <b>Goal:</b><br/>
                        Pass in your annual salary to find your corresponding income taxes (3): federal-income tax, state-income tax, and fica tax.<br/>
                        <br/>

                        Annual salary is calculated = (rent * 12 months) * (rent_budget/100).<br/>
                        <br/>

                        <b>Method</b><br/>
                        HTTP GET<br/>
                        <br/>

                        <b>API</b><br/>
                        <i>GET</i> <span className='highlight'>/api/v3/incometax/gettax</span><br/>
                        <br/>

                        <b>Headers:</b><br/>
                        Accept: application/json<br/>
                        Content-type: application/json<br/>
                        <br/>

                        <b>Request Body</b><br/>
                        JSON Object of 'requests', where 'requests' is a JSON Array of JSON Object containing annual_income<br/>
                    </p>

                    <section id="code-snippet-div">
                        <div id='code-snippet-toolBar-anacondaTheme'>Sample Request Body:</div>
                        <div id='code-snippet-insertCode-anacondaTheme'>
                            <pre>
{`{
    "requests": [
        "annual_income": "80000"
        ]
}`}
                            </pre>
                        </div>
                    </section>
                    <br/>

                    <p>
                        <b>Response Codes:</b><br/>
                        202: Accepted<br/>
                        401: Not Authorized<br/>
                        500: Internal Service Error<br/>
                        <br/>

                        <b>Response Body:</b>
                    </p>

                    <section id="code-snippet-div">
                        <div id='code-snippet-toolBar-anacondaTheme'>Sample Response Body:</div>
                        <div id='code-snippet-insertCode-anacondaTheme'>
                            <pre>
{`{
    "getAnnualIncome" : [{
        "federalEffectiveRate": .20
        "ficaEffectiveRate" : .02
        "stateEffectiveRate" : .07
    }]
}`}
                            </pre>
                        </div>
                    </section>
                    <br/>

                    <h2>Data Engineering</h2>
                    <p>
                        <b>Data Source</b><br/>
                        The goal of this data is to capture income tax information. The source of this data came from https://wwww.smartasset.com/ apis. For legal reasons, I wont go into great detail on the data acquisition but feel free to msg me if you're interested in how I got this data. SmartAsset is a website to allow you to calculate your income tax given your income and the state you reside in.<br/>
                        <br/>

                        <b>Data Onboarding Questions (7):</b><br/>
                        1. How are the users going to query the data?<br/>
                            &ensp;&ensp;- https rest api endpoint<br/>
                        2. Structured/unstructured? <br/>
                            &ensp;&ensp;- unstructured<br/>
                        3. File extension? <br/>
                            &ensp;&ensp;- json<br/>
                        4. Object storage partitions? <br/>
                            &ensp;&ensp;- year (format: yyyy)<br/>
                        5. Data size? <br/>
                            &ensp;&ensp;- 25kb<br/>
                        6. Load type? <br/>
                            &ensp;&ensp;- append<br/>
                        7. Load frequency? <br/>
                            &ensp;&ensp;- 1 time backfill.<br/>
                        <br/>

                        <b>Data Modeling</b>
                    </p>

                    <section className="code-snippet-div">
                        <div id='code-snippet-toolBar-slackTheme'>JSON Data Definition</div>
                        <div className='code-snippet-insertCode-slackTheme'>
                            <pre>
{`Entity: Data2024
    federalEffectiveRate: float
    ficaEffectiveRate: float
    stateEffectiveRate: float
    stateName: String
`}
                            </pre>
                        </div>
                    </section>

                </section>

            </div>
        </>
    );
}

export default RentCalculatorArchitecture;